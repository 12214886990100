@import url("./fonts/style.css");

.App {
  text-align: center;
  min-height: 100vh;
  font-family: 'Qanelas Regular';
}

.App-logo {
  height: 80px;
  pointer-events: none;
  margin: 40px;
}

.App-body {
  min-height: 100vh;
}

.App-body.home {
  color: white;
}

.App-body.about {
  background-color: white;
  color: black;
}

.App-body.contact {
  background-color: white;
  color: black;
}

.App-body.screenings {
  color: white;
}

nav ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.vimeo-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
  overflow: hidden;
}
.vimeo-wrapper iframe {
  width: 100vw;
  height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
  min-height: 100vh;
  min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.two-columns {
  column-count: 2;
}

.time-left {
  font-size: 80px;
  line-height: 1;
}

.screenings .App-logo {
  position: absolute;
  bottom: 40px;
  right: 40px;
  margin: 0;
}

.contact .App-logo {
  position: absolute;
  bottom: 40px;
  left: 40px;
  margin: 0;
}

.contact-text, .screenings-text {
  padding: 30px;
}

.vertical-center {
  min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh; /* These two lines are counted as one :-)       */
  display: flex;
  align-items: center;
}

video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}
.nav li {
  position: relative;
}

.nav li::after {
  content: "|";
  color: #fff;
  position: absolute;
  right: 0;
  top: 6px;
}

.nav li:last-child::after {
  display: none;
}

.nav .nav-link {
  color: #fff;
}

.contact .nav .nav-link, .about .nav .nav-link {
  color: #000;
}

.contact .nav li::after, .about .nav li::after {
  color: #000;
}

.nav .nav-link:focus, .nav .nav-link:hover{
    color: #999;
}

.days-to-go p {
  margin: 0;
  font-weight: 700;
}

.time-left {
  margin-right: 20px;
  font-weight: 700;
}

.home p {
  font-size: 1.2rem;
}

.contact a, .contact a:hover {
  color: #000;
}
